import general from './general.js'
import navigation from './navigation.js'
import footer from './footer.js'
import error from './error.js'

export default {
  general,
  navigation,
  footer,
  error,
}
