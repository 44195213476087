export default {
  trekking: 'Escursioni e Trekking',
  education: 'Educazione Ambientale',
  environment: 'Cura del Territorio',
  mazarol: 'Guide Mazarol',
  contacts: 'Contatti',
  newsletter: 'Newsletter',
  'discover-activity': 'Scopri di più',
  'discover-mazarol': 'Conosci il Mazarol',
}
