export default {
  'newsletter-title': 'Iscriviti alla<br/> nostra newsletter',
  'newsletter-text':
    'Riceverai ogni mese novità su escursioni e attività nelle Dolomiti Bellunesi.',
  'newsletter-cta': 'Iscriviti',
  'loghi-title': 'I nostri partners',
  'loghi-text':
    'Collaboriamo con partner esperti per offrire esperienze autentiche e scoprire insieme la bellezza della natura.',
  contacts: 'Contatti',
}
